<script setup lang="ts">
const _model = {
  CallUsPhoneNumber: '+44 (0)20 3540 1477',
}
</script>

<template>
  <div class="w-full border-4 border-brand border-x-0 border-b-0">
    <div class="container">
      <div class="w-full flex flex-col justify-center md:hidden">
        <span class="w-full text-center">
          {{ $t('contact-need-help', 'Need help completing your order?') }}
        </span>
        <br />
        <a class="rulertel text-center" href="tel:+442035401477">
          {{ $t('call-us', 'Call us') }} {{ _model.CallUsPhoneNumber }}
        </a>
      </div>
      <ul class="w-full flex flex-row flex-nowrap">
        <!--todo implement dynamically active step-->
        <li class="icon shoppingBagPhase px-3 py-3 w-1/3 hidden md:flex bg-darkLighter-500 shadow-inner">
          <p class="uppercase color-[#333] ml-10 my-0">{{ $t('shopping-bag', 'Shopping Bag') }}</p>
        </li>
        <li class="icon checkoutPhase px-3 py-3 w-1/3 hidden md:flex bg-darkLighter-500 shadow-inner">
          <p class="uppercase color-[#333] ml-10 my-0">{{ $t('checkout', 'Checkout') }}</p>
        </li>
        <li class="icon orderConfirmationPhase px-3 py-3 w-1/3 active font-bold bg-[#ffc13a] shadow-inner">
          <p class="uppercase ml-10 my-0">{{ $t('order-confirmation', 'Order Confirmation') }}</p>
        </li>
      </ul>
      <slot />
    </div>
  </div>
</template>

<style scoped lang="scss">
.icon {
  background-repeat: no-repeat;
  background-position: 8px;
}
.shoppingBagPhase {
  background-image: url('/images/img-2018/Checkout/Phase_ShoppingBag.png');
}

.checkoutPhase {
  background-image: url('/images/img-2018/Checkout/Phase_Checkout.png');
}
.orderConfirmationPhase {
  background-image: url('/images/img-2018/Checkout/Phase_OrderConfirmSelected.png');
}
</style>
